<template>
    <span class="mr-2">
    <b-badge pill variant="danger" class="">
        {{text}} <font-awesome-icon icon="times" class="close-button" @click="$emit('close-badge', badgeId)"></font-awesome-icon>
    </b-badge>
</span>
</template>
<script>

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Badge extends Vue {

    @Prop({type: Number})
    badgeId;

    @Prop({type: String})
    text;
}
</script>
<style>
    svg.close-button {
        cursor: pointer;
    }
</style>