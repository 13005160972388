<template>
    <b-card>
        <b-row class="mb-2">
            <b-col>
                <b-list-group>
                    <b-list-group-item>
                        <b-row>
                            <b-col cols="1">
                                <template>
                                    <b-button
                                            variant="light"
                                            block
                                            @click="showRecipientModal('to')">TO</b-button>
                                </template>
                                <template
                                        v-if="tos.length > 0">
                                    <b-button
                                            variant="danger"
                                            block
                                            @click="resetEmails('to')">Clear</b-button>
                                </template>
                            </b-col>
                            <b-col cols="11">
                                <b-form-group
                                        :state="errorState('tos')"
                                        invalid-feedback="At least one recipient (TO, CC, or BCC) is required">
                                    <b-form-tags
                                            add-button-variant="primary"
                                            :state="errorState('tos')"
                                            size="sm"
                                            placeholder="Enter or select TO email address(es) here"
                                            input-id="to-tags"
                                            :tag-validator="validEmail"
                                            separator=" ,;"
                                            remove-on-delete
                                            v-model="tos" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-row>
                            <b-col cols="1">
                                <template>
                                    <b-button
                                            variant="light"
                                            block
                                            @click="showRecipientModal('cc')">CC</b-button>
                                </template>
                                <template
                                        v-if="ccs.length > 0">
                                    <b-button
                                            variant="danger"
                                            block
                                            @click="resetEmails('cc')">Clear</b-button>
                                </template>
                            </b-col>
                            <b-col cols="11">
                                <b-form-group>
                                    <b-form-tags
                                            add-button-variant="primary"
                                            size="sm"
                                            placeholder="Enter or select CC email address(es) here"
                                            input-id="cc-tags"
                                            :tag-validator="validEmail"
                                            separator=" ,;"
                                            remove-on-delete
                                            v-model="ccs" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-row>
                            <b-col cols="1">
                                <template>
                                    <b-button
                                            variant="light"
                                            block
                                            @click="showRecipientModal('bcc')">BCC</b-button>
                                </template>
                                <template v-if="bccs.length > 0">
                                    <b-button
                                            variant="danger"
                                            block
                                            @click="resetEmails('bcc')">Clear</b-button>
                                </template>
                            </b-col>
                            <b-col cols="11">
                                <b-form-group>
                                    <b-form-tags
                                            add-button-variant="primary"
                                            size="sm"
                                            placeholder="Enter or select BCC email address(es) here"
                                            input-id="bcc-tags"
                                            :tag-validator="validEmail"
                                            separator=" ,;"
                                            remove-on-delete
                                            v-model="bccs" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-row>
                            <b-col cols="1">
                                <template v-if="attachments.length > 0">
                                    <b-button
                                            variant="danger"
                                            block
                                            @click="resetFiles">Clear</b-button>
                                </template>
                            </b-col>
                            <b-col cols="11">
                                <b-form-group>
                                    <b-form-file multiple
                                                 :accept="allowedAttachmentTypes"
                                                 placeholder="Attach files"
                                                 drop-placeholder="Drop file here"
                                                 v-model="attachments" />
                                </b-form-group>
                                <template v-if="attachments.length > 0">
                                    <ul>
                                        <li v-for="a in attachments">{{a.name}}</li>
                                    </ul>
                                </template>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <b-list-group-item>
                        <b-row>
                            <b-col>
                                <b-form-group
                                        :state="errorState('subject')"
                                        invalid-feedback="Subject is required">
                                    <b-input
                                            size="sm"
                                            :state="errorState('subject')"
                                            placeholder="Add a Subject"
                                            v-model="subject" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <div ref="editorWrapper">
                    <b-form-group
                            :state="errorState('body')"
                            invalid-feedback="Email body is required">
                        <ckeditor
                                :state="errorState('body')"
                                :editor="editor"
                                v-model="body"
                                :config="config"
                                @ready="editorReady" />
                    </b-form-group>
                </div>
            </b-col>
        </b-row>
        <template v-slot:footer>
            <b-button-group
                    size="sm"
                    class="float-right">
                <b-button
                        variant="danger"
                        @click="discard">Discard</b-button>
                <b-button
                        :disabled="disableSend"
                        variant="info"
                        @click="send">Send</b-button>
            </b-button-group>
        </template>
        <!--RECIPIENT MODAL-->
        <b-modal id="recipient-modal"
                 no-close-on-backdrop
                 no-close-on-esc
                 ref="recipient-modal"
                 size="lg"
                 title="Select Recipients"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 scrollable>
            <template>
                <div>
                    <b-row>
                        <b-col>
                            <strong>Type</strong>
                            <b-form-select :select-size="selectSize"
                                           @change="resetSubOption"
                                           :options="recipientTopOptions"
                                           v-model="recipientTopSelection" />
                            <br />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <strong>Subtype</strong>
                            <b-form-select :select-size="selectSize"
                                           @change="resetFilterOption"
                                           :options="recipientSubOptions"
                                           v-model="recipientSubSelection" />
                            <br />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <strong>Filter</strong>
                            <b-form-select :select-size="selectSize"
                                           :options="recipientFilterOptions"
                                           v-model="recipientFilterSelection"
                                           multiple />
                            <br />
                        </b-col>
                    </b-row>
                </div>
            </template>
            <template v-slot:modal-footer>
                <div>
                    <b-button-group size="sm" class="float-right">
                        <b-button variant="success"
                                  @click="resetAllOptions">Reset</b-button>
                        <b-button variant="primary"
                                  @click="cancel">Cancel</b-button>
                        <b-button variant="danger"
                                  @click="select"
                                  :disabled="disableSelect">Select</b-button>
                    </b-button-group>
                </div>
            </template>
        </b-modal>
    </b-card>
</template>
<script>

    import {Component, Vue} from 'vue-property-decorator';
    import store from '@/store/store';
    import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
    import Badge from '@/components/shared/Badge.vue';
    import CKEditor from '@ckeditor/ckeditor5-vue';
    import Editor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
    import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
    import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
    import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
    import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
    import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
    import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
    import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
    import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
    import Link from '@ckeditor/ckeditor5-link/src/link';
    import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
    import Font from '@ckeditor/ckeditor5-font/src/font';
    import Indent from '@ckeditor/ckeditor5-indent/src/indent';
    import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
    import List from '@ckeditor/ckeditor5-list/src/list';
    import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
    import Heading from '@ckeditor/ckeditor5-heading/src/heading';
    import Clipboard from '@ckeditor/ckeditor5-clipboard/src/clipboard';
    import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
    import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
    import Colors from './colors';
    import {RecipientTopOptions, Envelope} from '@/model/email';
    import emailDao from '@/dao/email_dao';
    import _ from 'underscore';
    import formatters from '@/util/formatters';
    import {sprintf} from 'sprintf-js';
    import {validEmailAddress} from '@/util/validation';
    import {NULL_ENVELOPE} from "../../../model/email";
    import {Session, WorkshopInstance} from "../../../model/session";
    import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

    @Component({
        components: {
            ckeditor: CKEditor.component,
            Badge
        }
    })

    export default class Email extends Vue {
        //Email properties
        tos = [];
        ccs = [];
        bccs = [];
        attachments = [];
        subject = '';
        body = '';
        //Modal properties
        selectSize = 5;
        recipientTopSelection = null;
        recipientSubSelection = null;
        recipientFilterSelection = [];
        recipientType = null;

        editor = Editor;
        config = {
            plugins: [
                Essentials, Paragraph,
                Bold, Italic, Strikethrough, Underline, Code, Subscript, Superscript,
                Link, List, Font, Alignment, Indent, IndentBlock, BlockQuote,
                Heading, Clipboard, RemoveFormat
            ],

            fontSize: {
                options: _.range(12, 50).filter((i) => {
                    return i < 18 ||
                        (i < 30 && 0 === i % 2) ||
                        (i < 50 && 0 === i % 4) ||
                        (i < 80 && 0 === i % 6) ||
                        0 === i % 8;
                })
            },

            fontColor: {
                colors: Colors
            },

            fontBackgroundColor: {
                colors: Colors
            },

            indentBlock: {
                offset: 1,
                unit: 'em'
            },

            toolbar: {
                items: [
                    'heading', 'paragraph', '|',
                    'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'alignment', '|',
                    'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', '|',
                    'bulletedList', 'numberedList', 'indent', 'outdent', 'blockQuote', '|',
                    'link', '|',
                    'removeformat', 'undo', 'redo'
                ]
            }
        };

        editorReady(editor) {
            this.$refs.editorWrapper.prepend(editor.ui.view.toolbar.element);
        }

        get allowedAttachmentTypes() {
            return '.jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .tiff, .rtf, .log';
        }

        resetEnvelope() {
            this.tos = [];
            this.ccs = [];
            this.bccs = [];
            this.attachments = [];
            this.subject = '';
            this.body = '';
        }

        async resetFiles() {
            const check = await this.$bvModal.msgBoxConfirm(
                'Are you sure you want to clear all attachments?', {
                    title: 'Confirm',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (check) {
                this.attachments = [];
            }
        }

        async resetEmails(recipientType) {
            const check = await this.$bvModal.msgBoxConfirm(
                sprintf('Are you sure you want to clear all %s email addresses?', recipientType.toUpperCase()), {
                    title: 'Confirm',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                });
            if (check) {
                switch (recipientType) {
                    case 'to':
                        this.tos = [];
                        return;
                    case 'cc':
                        this.ccs = [];
                        return;
                    case 'bcc':
                        this.bccs = [];
                        return;
                }
            }
        }

        validEmail(testEmail) {
            return validEmailAddress(testEmail);
        }

        showRecipientModal(recipientType) {
            this.recipientType = recipientType;
            this.$refs['recipient-modal'].show();
        }

        async discard() {
            if (await this.$bvModal.msgBoxConfirm(
                'Are you sure you want to discard this email?', {
                    title: 'Confirm',
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    headerBgVariant: 'dark',
                    headerTextVariant: 'white'
                }
            )) {
                this.resetEnvelope();
            }
        }

        resetAllOptions() {
            this.recipientTopSelection = null;
            this.recipientSubSelection = null;
            this.recipientFilterSelection = [];
        }

        resetSubOption() {
            this.recipientSubSelection = null;
            this.recipientFilterSelection = [];
        }

        resetFilterOption() {
            this.recipientFilterSelection = [];
        }

        cancel() {
            this.resetAllOptions();
            this.$refs['recipient-modal'].hide();
        }

        async select() {
            //Build an object to hold search criteria
            const emailRequest = {
                top: this.recipientTopSelection.serverValue,
                sub: this.recipientSubSelection.serverValue,
                filter: this.recipientFilterSelection
            };
            //Retrieve email address results
            //DAO alerts
            try {
                const emails = await emailDao.getEmailAddresses(emailRequest);
                //No results
                if (_.isEmpty(emails)) {
                    this.cancel();
                    return;
                }
                //Add results to recipient structures
                switch (this.recipientType) {
                    case 'to':
                        this.tos = this.tos.concat(emails);
                        break;
                    case 'cc':
                        this.ccs = this.ccs.concat(emails);
                        break;
                    case 'bcc':
                        this.bccs = this.bccs.concat(emails);
                        break;
                }
                //Clear and exist
                this.cancel();
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        get disableSelect() {
            //Cannot submit without subtype OR filter selection if options are present
            return _.isNull(this.recipientSubSelection) ||
                (!_.isEmpty(this.recipientFilterOptions) && _.isEmpty(this.recipientFilterSelection));
        }

        get recipientTopOptions() {
            return _.map(RecipientTopOptions, (option) => {
                return {
                    value: option,
                    text: option.label
                };
            });
        }

        get recipientSubOptions() {
            if (_.isNull(this.recipientTopSelection)) {
                return [];
            }
            return _.map(this.recipientTopSelection.subOptions, (subOption) => {
                return {
                    value: subOption,
                    text: subOption.label
                };
            });
        }

        get recipientFilterOptions() {
            if (_.isNull(this.recipientSubSelection)) {
                return [];
            }
            const getFilterOptions = this.recipientSubSelection.filter.options;
            return getFilterOptions(this.$store.getters);
        }

        get checkErrorFields() {
            return ['tos', 'ccs', 'bccs', 'subject', 'body', 'attachments'];
        }

        errorState(fieldName) {
            switch (fieldName) {
                case 'tos':
                case 'ccs':
                case 'bccs':
                    //Email needs to have at least one recipient
                    return !(_.isEmpty(this.tos) && _.isEmpty(this.ccs) && _.isEmpty(this.bccs));
                case 'subject':
                    return !_.isEmpty(this.subject);
                case 'body':
                    return !_.isEmpty(this.body);
                case 'attachments':
                default:
                    //Not required
                    return true;
            }
        }

        get disableSend() {
            const status = _.any(this.checkErrorFields, field => {
                const fieldStatus = !this.errorState(field);
                return fieldStatus;
            });
            return status;
        }

        async send() {
            //Build individual components into an envelope, because the UI doesn't like when attachments gets "serialized"
            const envelope = Envelope.create({
                tos: this.tos,
                ccs: this.ccs,
                bccs: this.bccs,
                attachments: this.attachments,
                subject: this.subject,
                body: this.body
            });
            try {
                const success = await emailDao.sendEmail(envelope);
                //DAO validates success, so this should always be true
                if (success) {
                    await this.$bvModal.msgBoxOk('Your message has been sent', {
                        title: 'Message Sent',
                        size: 'sm',
                        buttonSize: 'sm',
                        centered: true
                    });
                    this.resetEnvelope();
                }
            }
            catch (error) {
                await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            }
        }

        async beforeRouteEnter(to, from, next) {
            store.commit('setBreadcrumbs', [
                Breadcrumb.create('Administration', {name: 'adminMain'}, false),
                Breadcrumb.create('Email', null, true)
            ]);
            try {
                await store.dispatch('tracks/loadTracks');
                await store.dispatch('organizations/loadContractors');
                await store.dispatch('organizations/loadContractorTypes');
                await store.dispatch('users/loadTraineeStatuses');
            }
            catch (error) {
                const vm = new Vue();
                vm.$bvToast.toast(error.message, errorToastOptions);
            }
            next();
        }
    }
</script>

<style>
    div.ck-content {
        height: 250px;
    }
    ul.ck-list {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
